/**
 * Translate description: Index page for news
 */

import { useEffect, useContext } from 'react';

import { langByCode, useLanguageContext } from "../../context/LanguageContext";
import AppContext from '../../context/AppContext';
import InfoContext from '../../context/InfoContext';
import AppProvider from '../../provider/AppProvider';

import Bread from '../../components/layout/BreadCrumbs';
import Title from '../../components/layout/Title';
import ModalDelete from '../../components/layout/ModalDelete';

import { AddButton } from '../../components/blocks/AddButton';

import Typography from '@mui/joy/Typography';

import Content from './components/Content';


import { Crud } from '../../utils/crud';

const crud = new Crud('news');

function _Index() {
  const { t } = useLanguageContext();

  const { setInfo } = useContext(InfoContext);
  const { setListItems } = useContext(AppContext);
  const { paginator, setPaginator } = useContext(AppContext);
  const { sorting } = useContext(AppContext);
  const { filter, setFilter } = useContext(AppContext);
  const { loading, setLoading } = useContext(AppContext);
  const { currentLang } = useContext(AppContext);

  useEffect(() => {
    if (currentLang) {
      setFilter((filter) => ({ ...filter, lang: { lang_id: langByCode(currentLang).id.toString(), filter_type: filter?.lang?.filter_type || "all" } }))
    }
  }, [currentLang]);

  useEffect(() => {
    const loadTable = async () => {
      if (!filter?.lang) return;
      // if (loading) return;
      setLoading(true);
      if (!sorting.sort) {
        sorting.sort = 'id';
        sorting.order = 'desc';
      }
      let list = await crud.get({ paginator, sorting, filter })
      if (list.is_error) {
        setInfo({ type: 'danger', show: true, message: `${t('Error')}` });
        setTimeout(() => setInfo({ show: false }), 6000)
        setLoading(false);
        return;
      }
      setLoading(false);
      setListItems(list.data);
      setPaginator((p) => ({ ...p, totalPages: list.pages }));
    }
    loadTable();
  }, [paginator.currentPage, filter.lang, filter.status_in, filter.search, sorting]);


  return (
    <>
      <Bread path={[{ name: t("News") }]} />
      <Title>
        <Typography level="h2" component="h1">{t("News")}</Typography>
        <AddButton label={t("New news")} to={`/news/add/${currentLang}`} />
      </Title>
      <Content />
      <ModalDelete />
    </ >
  );
}

export default function Index() {
  return (
    <AppProvider>
      <_Index />
    </AppProvider>
  );
}