/**
 * Translate description: Table header sort
 */

import { useContext } from 'react';
import Link from '@mui/joy/Link';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AppContext from '../../context/AppContext';

export default function TableHeaderSort({ children, sortBy }) {
    const { sorting, setSorting } = useContext(AppContext);
    return (<Link
        underline="none"
        color="primary"
        component="button"
        onClick={() => {
            if (sorting.sort !== sortBy) {
                setSorting({ sort: sortBy, order: 'asc' });
            } else {
                setSorting({ sort: sortBy, order: sorting.order === 'asc' ? 'desc' : 'asc' });
            }
        }}
        fontWeight="lg"
        endDecorator={sorting.sort === sortBy ? <ArrowDropDownIcon /> : ''}
        sx={{
            '& svg': {
                transition: '0.2s',
                transform:
                    sorting.order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
            },
        }}
    >
        {children}
    </Link>)
}