/**
 * Translate description: Filter component
 */

import { Fragment, useContext } from 'react';

import { useLanguageContext } from "../../context/LanguageContext";
import AppContext from '../../context/AppContext';

import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';

export default () => {
  const { t } = useLanguageContext();
  const { filter, setFilter, setPaginator } = useContext(AppContext);
  return (
    <Fragment>
      <FormControl size="sm">
        <FormLabel>{t('Status')}</FormLabel>
        <Select
          size="sm"
          placeholder={t('Filter by status')}
          slotProps={{
            button: { sx: { whiteSpace: 'nowrap' } },
            listbox: { placement: 'bottom-end' },
          }}
          onChange={(e, v) => {
            let statuses = {
              "all": [1, 2],
              "draft": [1],
              "published": [2]
            }
            setFilter({ ...filter, status_in: statuses[v] });
            setPaginator({ currentPage: 1 });
          }}
          defaultValue=""
        >
          <Option value={"all"}>{t('All')}</Option>
          <Option value={"draft"}>{t('Draft')}</Option>
          <Option value={"published"}>{t('Published')}</Option>
        </Select>
      </FormControl>
    </Fragment>
  )
};
