/**
 * Translate description: Content table component
 */

import { Fragment, useContext } from 'react';
import { Link as ReactLink } from 'react-router-dom';

import { useLanguageContext } from "../../context/LanguageContext";
import AppContext from '../../context/AppContext';

import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import Typography from '@mui/joy/Typography';

import Pagination from '../../components/layout/Pagination';
import ItemEditDelete from '../../components/layout/ItemEditDelete';
import TableHeaderSort from '../layout/TableHeaderSort';
import { Loading } from '../../components/loading';

import { capitalizeFirstLetter } from '../../utils';

export function ContentTable({ children }) {
  const { loading, selected, paginator, setPaginator } = useContext(AppContext);

  return (
    <Fragment>
      {selected?.length ? (<Box>Выбрано элементов: {selected.length}</Box>) : null}

      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: 'none', sm: 'initial' },
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
          }}
        >
          {children}
        </Table>
      </Sheet>
      {!loading && <Pagination paginator={paginator} setPaginator={setPaginator} />}
    </Fragment>
  );
}


export function ContentHeaderItem({ title, sortBy, width }) {
  if (sortBy) return (<th style={{ padding: '12px 6px' }} width={width ?? 'auto'}><TableHeaderSort sortBy={sortBy}>{title}</TableHeaderSort></th>)
  return (<th style={{ padding: '12px 6px' }} width={width ?? 'auto'}>{title}</th>)
}


export function ContentHeader({ children }) {
  const { selected, setSelected, listItems } = useContext(AppContext);
  return (
    <thead>
      <tr>
        <th style={{ width: 48, textAlign: 'center', padding: '12px 6px' }} width={48}>
          <Checkbox
            size="sm"
            indeterminate={
              selected.length > 0 && selected.length !== listItems?.length
            }
            checked={selected.length === listItems?.length}
            onChange={(event) => {
              setSelected(
                event.target.checked ? listItems?.map((row) => row.id) : [],
              );
            }}
            color={
              selected.length > 0 || selected.length === listItems?.length
                ? 'primary'
                : undefined
            }
            sx={{ verticalAlign: 'text-bottom' }}
          />
        </th>
        {children}
      </tr>
    </thead>
  )
}


export function ContentBodyCheckbox({ row }) {
  const { selected, setSelected } = useContext(AppContext);
  return (
    <td style={{ textAlign: 'center', width: 120 }}>
      <Checkbox
        size="sm"
        checked={selected.includes(row.id)}
        color={selected.includes(row.id) ? 'primary' : undefined}
        onChange={(event) => {
          setSelected((ids) =>
            event.target.checked
              ? ids.concat(row.id)
              : ids.filter((itemId) => itemId !== row.id),
          );
        }}
        slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
        sx={{ verticalAlign: 'text-bottom' }}
      />
    </td>
  )
}


export function ContentBodyItem({ children, to, textAlign }) {
  if (to) return (<td><Typography level="body-xs"><ReactLink to={to} style={{ textDecoration: 'none' }}><Typography color='primary'>{children}</Typography></ReactLink></Typography></td>);
  return (<td><Typography level="body-xs" textAlign={textAlign ? textAlign : 'left'}>{children}</Typography></td>);
}


export function ContentBodyItemTranslations({ row, textAlign, editLink }) {
  const translations = row.trans ?? row.translations ?? [];
  return (<td><Typography level="body-xs" textAlign={textAlign ? textAlign : 'left'}>
    {translations.length > 0 && (
      translations.map((v, i) =>
        <Typography color='primary' key={i}>
          <ReactLink to={`/${editLink}/${v.entity_id}`} style={{ textDecoration: 'none' }}><Typography color='primary'>{v.code.toUpperCase()}</Typography></ReactLink>
          {(i < (translations.length - 1)) ? ', ' : ''}
        </Typography>)
    )}
  </Typography></td>);
}


export function ContentBodyItemStatus({ row, textAlign }) {
  const { t } = useLanguageContext();
  return (<td><Typography level="body-xs" textAlign={textAlign ? textAlign : 'left'}>
    {t(capitalizeFirstLetter(row.status?.name ?? 'New'))}
  </Typography></td>);
}


export function ContentBodyItemPublished({ row, textAlign }) {
  return (<td><Typography level="body-xs" textAlign={textAlign ? textAlign : 'left'}>
    {row.published_at?.replace(/^(\d{4})-(\d{2})-(\d{2}).*/, '$3.$2.$1')}
  </Typography></td>);
}


export function ContentBodyItemImage({ image, textAlign }) {
  return (<td><Typography level="body-xs" textAlign={textAlign ? textAlign : 'left'}>
    {image ? <img src={image} style={{ maxWidth: '100%' }} /> : 'No image'}
  </Typography></td>);
}


export function ContentBodyItemEdit({ row, addLink, editLink, deleteLink, addAppend }) {
  const { t } = useLanguageContext();
  const { currentLang } = useContext(AppContext);
  if (!addAppend) addAppend = '';
  return (<td><Typography level="body-xs" textAlign={'right'}>
    {currentLang === row.language.code ? (
      <ItemEditDelete id={row.id} editLink={editLink} deleteLink={deleteLink} />
    ) : (
      <ReactLink to={`/${addLink}/add${addAppend}/${currentLang}/${row.id}`}>
        <Button size="sm" variant="soft">{t('Create')}</Button>
      </ReactLink>
    )}
  </Typography></td>)
}


export function ContentBodyItemEditOnly({ row, addLink, editLink, deleteLink }) {
  const { t } = useLanguageContext();
  const { currentLang } = useContext(AppContext);
  return (<td><Typography level="body-xs" textAlign={'right'}>
    <ItemEditDelete id={row.id} editLink={editLink} deleteLink={deleteLink} />
  </Typography></td>)
}


export function ContentBody({ children, row, colspan }) {
  const { t } = useLanguageContext();
  const { loading, listItems } = useContext(AppContext);
  return (<tbody>
    {children}
    {loading ? <tr><td colSpan={colspan}><Loading /></td></tr> : null}
    {listItems?.length === 0 && !loading ? (
      <tr><td colSpan={colspan}><Typography level="body-lg" sx={{ textAlign: 'center' }}>{t('No data')}</Typography></td></tr>
    ) : null}
  </tbody>)
}

