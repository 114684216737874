/**
 * Translate description: Content list component
 */

import { useContext } from 'react';
import { useLanguageContext } from "../../context/LanguageContext";
import AppContext from '../../context/AppContext';

import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemContent from '@mui/joy/ListItemContent';
import ListDivider from '@mui/joy/ListDivider';

import PaginationMobile from '../../components/layout/PaginationMobile';
import ItemEditDelete from '../../components/layout/ItemEditDelete';
import { Loading } from '../../components/loading';

export function ContentList({ children }) {
  const { t } = useLanguageContext();
  const { listItems } = useContext(AppContext);
  const { paginator, setPaginator } = useContext(AppContext);
  const { loading } = useContext(AppContext);

  return (
    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
      {loading ? <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}><Loading /></Box> : null}
      {children}
      {listItems?.length === 0 && !loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}><Typography level="body-lg">{t('No data')}</Typography></Box>
      ) : null}
      {listItems?.length ? (<PaginationMobile paginator={paginator} setPaginator={setPaginator} />) : null}
    </Box>
  );
}

export function ContentListRow({ children, row, editLink, deleteLink }) {
  return (
    <List
      key={row.id}
      size="sm"
      sx={{
        '--ListItem-paddingX': 0,
      }}
    >
      <ListItem
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
        }}
      >
        <ListItemContent sx={{ display: 'flex', gap: 2, alignItems: 'start' }}>
          <div>
            {children}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 0.5,
                mb: 1,
              }}
            >
              {/* <Typography level="body-xs">&bull;</Typography> */}
              <Typography level="body-xs">{row.id}</Typography>
            </Box>
          </div>
        </ListItemContent>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
          <ItemEditDelete id={row.id} editLink={editLink} deleteLink={deleteLink} />
        </Box>
      </ListItem>
      <ListDivider />
    </List >
  );
}

export function ContentListRowCustom({ children, row, button }) {
  return (
    <List
      key={row.id}
      size="sm"
      sx={{
        '--ListItem-paddingX': 0,
      }}
    >
      <ListItem
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
        }}
      >
        <ListItemContent sx={{ display: 'flex', gap: 2, alignItems: 'start' }}>
          <div>
            {children}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 0.5,
                mb: 1,
              }}
            >
              {/* <Typography level="body-xs">&bull;</Typography> */}
              <Typography level="body-xs">{row.id}</Typography>
            </Box>
          </div>
        </ListItemContent>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
          {button}
        </Box>
      </ListItem>
      <ListDivider />
    </List >
  );
}



export function ContentListItem({ children }) {
  return (<Typography level="body-xs" gutterBottom>{children}</Typography>)
}