import { useState, useEffect, Suspense } from 'react';

import { checkAuthStatus } from './utils/authService';
import UserContext from './context/UserContext';
import RouteList from './Routes';

import './style.css';

export default function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchAuthStatus = async () => {
      const user = await checkAuthStatus();
      setIsAuthenticated(!!user.login);
      setUser(user);
    };
    fetchAuthStatus();
  }, []);

  const logoutUser = () => {
    setUser({});
    setIsAuthenticated(false);
  };

  const loginUser = async () => {
    const user = await checkAuthStatus();
    setIsAuthenticated(!!user.login);
    setUser(user);
  };
  if (isAuthenticated === null) return <></>;
  return (
    <UserContext.Provider value={{ user, loginUser, logoutUser }}>
      <Suspense fallback={<div style={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>Loading...</div>}>
        <RouteList isauth={isAuthenticated} />
      </Suspense>
    </UserContext.Provider>
  );
}