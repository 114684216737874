/**
 * Translate description: Filter component
 */

import { Fragment, useState, useContext } from 'react';

import { useLanguageContext } from "../../context/LanguageContext";
import AppContext from '../../context/AppContext';

import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';

import DebounceInput from '../fields/DebouncedInput';

const renderFilters = children => {
  return (<Fragment>{children}</Fragment>)
};

export default function Filters({ children }) {
  const { t } = useLanguageContext();
  const [open, setOpen] = useState(false);
  const { setPaginator, filter, setFilter } = useContext(AppContext);

  const handleDebounce = (e) => {
    setFilter({ ...filter, search: e.target.value });
    setPaginator({ currentPage: 1 });
  };

  return (
    <>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{ display: { xs: 'flex', sm: 'none' }, my: 1, gap: 1 }}
      >
        <DebounceInput
          size="sm"
          placeholder={t('Search')}
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
          debounceTimeout={500}
          handleDebounce={handleDebounce}
        />
        {!!children && <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          onClick={() => setOpen(true)}
        >
          <FilterAltIcon />
        </IconButton>}
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              {t('Filters')}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {renderFilters(children)}
              <Button color="primary" onClick={() => setOpen(false)}>
                {t('Submit')}
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          py: 2,
          display: { xs: 'none', sm: 'flex' },
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: { xs: '120px', md: '160px' },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>{t('Search')}</FormLabel>
          <DebounceInput size="sm"
            placeholder={t('Search')}
            startDecorator={<SearchIcon />}
            debounceTimeout={500}
            handleDebounce={handleDebounce}
          />
        </FormControl>
        {!!children && renderFilters(children)}
      </Box>
    </>
  )
}