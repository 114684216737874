/**
 * Translate description: Table component
 */

import { Fragment, useContext } from 'react';

import { useLanguageContext } from "../../../context/LanguageContext";
import AppContext from '../../../context/AppContext';

import Filter from '../../../components/filter/Filter';
import FilterSourceLang from '../../../components/filter/FilterSourceLang';
import FilterTranslated from '../../../components/filter/FilterTranslated';
import FilterStatus from '../../../components/filter/FilterStatus';

import {
  ContentTable, ContentHeader, ContentHeaderItem, ContentBody,
  ContentBodyCheckbox, ContentBodyItem,
  ContentBodyItemStatus,
  ContentBodyItemTranslations, ContentBodyItemEdit
} from '../../../components/blocks/ContentTable';

import { ContentList, ContentListRow, ContentListItem } from '../../../components/blocks/ContentList';

export default function Content() {
  const { t } = useLanguageContext();
  const { listItems } = useContext(AppContext);
  const { loading } = useContext(AppContext);

  return (
    <Fragment>
      <Filter>
        <FilterSourceLang />
        <FilterTranslated />
        <FilterStatus />
      </Filter>

      <ContentTable>
        <ContentHeader>
          <ContentHeaderItem title={t('ID')} sortBy="id" width={50} />
          <ContentHeaderItem title={t('Name')} sortBy="name" />
          <ContentHeaderItem title={t('Translations')} width={120} />
          <ContentHeaderItem title={t('Url')} />
          <ContentHeaderItem title={t('Status')} width={100} />
          <ContentHeaderItem width={100}></ContentHeaderItem>
        </ContentHeader>

        <ContentBody colspan={7}>
          {(Array.isArray(listItems) && !loading && listItems?.length) ? (
            listItems.map((row) => (
              <tr key={row.id}>
                <ContentBodyCheckbox row={row} />
                <ContentBodyItem>{row.id}</ContentBodyItem>
                <ContentBodyItem to={`/page/${row.id}`}>{row.name}</ContentBodyItem>
                <ContentBodyItemTranslations row={row} editLink="page" />
                <ContentBodyItem>{row.top_level ? row.url : `/${row.language.code}${row.url}`}</ContentBodyItem>
                <ContentBodyItemStatus row={row} />
                <ContentBodyItemEdit row={row} addLink='pages' editLink='page' deleteLink='pages' />
              </tr>
            ))
          ) : null}
        </ContentBody>
      </ContentTable>

      <ContentList>
        {(Array.isArray(listItems) && !loading && listItems?.length) ? (
          listItems.map((row) => (
            <ContentListRow key={row.id} row={row} editLink={'page'} deleteLink={'pages'}>
              <ContentListItem>{row.name}</ContentListItem>
              <ContentListItem>{row.slug}</ContentListItem>
            </ContentListRow>
          ))
        ) : null}
      </ContentList>

    </Fragment >
  );
}