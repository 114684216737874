/**
 * Translate description: Filter component
 */

import { Fragment } from 'react';

import { useLanguageContext } from "../../context/LanguageContext";

import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import SelectSourceLang from '../SelectSourceLang';

export default () => {
  const { t } = useLanguageContext();
  return (
    <Fragment>
      <FormControl size="sm">
        <FormLabel>{t('Source language')}</FormLabel>
        <SelectSourceLang />
      </FormControl>
    </Fragment>
  )
};
