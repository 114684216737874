import React, { useState } from 'react';

import AppContext from '../context/AppContext';
import { useLanguageContext } from "../context/LanguageContext";

export default function AppProvider({ children }) {
  let LSSourceLangCode = localStorage.getItem('i18nextSrcLng')
  if (!LSSourceLangCode) {
    localStorage.setItem('i18nextSrcLng', 'en')
    LSSourceLangCode = localStorage.getItem('i18nextSrcLng')
  }

  const { langs_db } = useLanguageContext();

  const [listItems, setListItems] = useState([]);
  const [paginator, setPaginator] = useState({ currentPage: 1, totalPages: 0 });
  const [sorting, setSorting] = useState({ sortBy: '', order: 'asc' });
  const [filter, setFilter] = useState({ search: '', filter: '' });
  const [modal, setModal] = useState({ id: 0, link: '', title: '', show: false });
  const [loading, setLoading] = useState(false);
  const [currentLang, setCurrentLang] = useState(LSSourceLangCode);
  const [selected, setSelected] = useState([]);

  return (
    <AppContext.Provider value={{
      languages: langs_db,
      currentLang,
      currentLangId: (langs_db.find(x => x.code == currentLang)?.id || 1),
      setCurrentLang,

      listItems, setListItems,
      paginator, setPaginator,
      sorting, setSorting,
      filter, setFilter,
      modal, setModal,
      loading, setLoading,
      selected, setSelected
    }}>
      {children}
    </AppContext.Provider>
  );
}