/**
 * Translate description: Filter component
 */

import { Fragment, useContext } from 'react';

import { useLanguageContext } from "../../context/LanguageContext";
import AppContext from '../../context/AppContext';

import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';

export default () => {
  const { t } = useLanguageContext();
  const { currentLangId, filter, setFilter, setPaginator } = useContext(AppContext);
  return (
    <Fragment>
      <FormControl size="sm">
        <FormLabel>{t('Translate')}</FormLabel>
        <Select
          size="sm"
          placeholder={t('Filter by translate')}
          slotProps={{
            button: { sx: { whiteSpace: 'nowrap' } },
            listbox: { placement: 'bottom-end' },
          }}
          onChange={(e, v) => {
            setFilter({ ...filter, lang: { lang_id: currentLangId.toString(), filter_type: v } });
            setPaginator({ currentPage: 1 });
          }}
        >
          <Option value="all">{t('All')}</Option>
          <Option value="only">{t('Translated')}</Option>
          <Option value="not">{t('Not translated')}</Option>
        </Select>
      </FormControl>
    </Fragment>
  )
};
